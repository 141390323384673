/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import clsx from 'clsx';

import styles from './App.module.scss';

function App() {
  return (
    <div className={clsx(styles.container)}>
      <div className={clsx(styles['sidebar'], styles['fixed-sidebar'], "bg-gray-800 text-gray-100")}>
        <div className="p-4">
          <h1 className="text-xl font-bold text-center">account @ kloubert.dev</h1>
        </div>
      </div>

      <header className={clsx(styles["fixed-header"], "bg-gray-900 text-gray-100 shadow-lg w-full")}>
        <div className="p-4">
          <h1 className="text-xl font-bold">Header</h1>
        </div>
      </header>

      <div className={clsx(styles["main-content"])}>
        <div className="p-4">
          <h2 className="text-lg font-semibold">Main Content</h2>
          <p>This is the main content area. It will scroll if content exceeds viewport height.</p>
        </div>
      </div>
    </div>
  );
}

export default App;
