import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './index.scss';

const SessionGate: React.FC<any> = ({ children }: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkSession = useCallback(() => {
    setIsLoggedIn(false);

    return new Promise((resolve, reject) => {
      setTimeout(resolve, 5000);
    });
  }, []);

  const renderContent = useCallback(() => {
    if (!isLoggedIn) {
      return (
        <div className="flex h-screen">
          <div className="m-auto">
            <span className="loading loading-dots loading-lg"></span>
          </div>
        </div>
      );
    }

    return children;
  }, [children, isLoggedIn]);

  useEffect(() => {
    checkSession()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch(console.error);
  }, [checkSession]);

  return renderContent();
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SessionGate>
      <App />
    </SessionGate>
  </React.StrictMode>
);
